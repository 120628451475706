@font-face {
  font-family: 'SF Pro Text Regular';
  src:
    url('res/fonts/sf-pro-text-regular/sf-pro-text-regular.woff2') format('woff2'),
    url('res/fonts/sf-pro-text-regular/sf-pro-text-regular.woff') format('woff'),
    url('res/fonts/sf-pro-text-regular/sf-pro-text-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conquera Heavy';
  src:
    url('res/fonts/conquera-heavy/conquera-heavy.woff2') format('woff2'),
    url('res/fonts/conquera-heavy/conquera-heavy.woff') format('woff'),
    url('res/fonts/conquera-heavy/conquera-heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}